// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-about-jsx": () => import("./../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-about-site-jsx": () => import("./../src/pages/about-site.jsx" /* webpackChunkName: "component---src-pages-about-site-jsx" */),
  "component---src-pages-docs-typography-jsx": () => import("./../src/pages/docs/typography.jsx" /* webpackChunkName: "component---src-pages-docs-typography-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-styleguide-jsx": () => import("./../src/pages/styleguide.jsx" /* webpackChunkName: "component---src-pages-styleguide-jsx" */),
  "component---src-templates-category-jsx": () => import("./../src/templates/category.jsx" /* webpackChunkName: "component---src-templates-category-jsx" */),
  "component---src-templates-listing-books-jsx": () => import("./../src/templates/listing-books.jsx" /* webpackChunkName: "component---src-templates-listing-books-jsx" */),
  "component---src-templates-listing-jsx": () => import("./../src/templates/listing.jsx" /* webpackChunkName: "component---src-templates-listing-jsx" */),
  "component---src-templates-post-book-jsx": () => import("./../src/templates/post-book.jsx" /* webpackChunkName: "component---src-templates-post-book-jsx" */),
  "component---src-templates-post-jsx": () => import("./../src/templates/post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */),
  "component---src-templates-tag-jsx": () => import("./../src/templates/tag.jsx" /* webpackChunkName: "component---src-templates-tag-jsx" */)
}

